import { FaCashRegister } from "react-icons/fa";
import { TableCashRegister } from "../app/components/table/cash-register";
import { useGetAllCashRegisterQuery, useLazyGetAllPagesCashRegisterQuery } from "../app/services/cashRegisterApi"
import { useMemo, useState } from "react";
import { Button } from "../app/components/buttons/button";
import { useDisclosure } from "@nextui-org/react";
import { CashRegisterDeposit } from "../app/components/cash-register-deposit";
import { Balance } from "../app/components/balance";
import { useCreateContext } from "../context-provider";
import { AlertSuccess } from "../app/components/alert/alert-success";

export const CashRegister = () => {
     const [page, setPage] = useState<number>(1)
     const [limit] = useState<number>(20)
     const { data, isLoading } = useGetAllCashRegisterQuery({ page, limit })
     const { isOpen, onOpen, onOpenChange } = useDisclosure();
     const { alert, classFrames, typeAlert } = useCreateContext()
     const [triggerGetAllPagesCashRegister, { data: dataPage }] = useLazyGetAllPagesCashRegisterQuery();

     const fn = async () => {
          await triggerGetAllPagesCashRegister();
     }

     const dataDownload = useMemo(() => {
          return dataPage ? dataPage : []
     }, [fn])


     return (

          <div className="flex flex-col container-table">
               <Button
                    icon={<FaCashRegister />}
                    type={`button`}
                    color={`success`}
                    variant={"flat"}
                    onPress={onOpen}
                    className="button-add"
               >Внести кассу</Button>
               <Balance />
               {alert && typeAlert === `add` && < AlertSuccess
                    type="success"
                    message={`Касса внесена`}
                    classFrames={classFrames}
               />}
               <TableCashRegister data={data} limit={limit} isLoading={isLoading} page={page} setPage={setPage} dataDownload={dataDownload} fn={fn} />
               <CashRegisterDeposit isOpen={isOpen} onOpenChange={onOpenChange} page={page} limit={limit} />
          </div>
     )
}
